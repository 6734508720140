import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';

import fy24a from '../../assests/sgPdf/credit_rating/FY 2024-25/CP-ICRA_Rating disclsoure.pdf';
import fy24b from '../../assests/sgPdf/credit_rating/FY 2024-25/CRISIL Rating.pdf';
import fy24c from '../../assests/sgPdf/credit_rating/FY 2024-25/ICRA Rating_29.11.2024.pdf';




const CreditRating = () => {

    const [selectedOption, setSelectedOption] = useState("option0"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Newspaper-publishing</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Newspaper-publishing </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>

                                <option value="option0">FY 2024-25</option>




                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}


                        {selectedOption === "option0" && (
                            <>

                              

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24b} target='blank'>  <h6>CRISIL Rating  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24a} target='blank'>  <h6>CP-ICRA_Rating disclsoure  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24c} target='blank'>  <h6>ICRA Rating_29.11.2024  </h6> </a>
                                </div>

                               




                            </>

                        )}



                        




                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}



export default CreditRating